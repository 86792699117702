/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Card, Col } from 'antd';
import { find } from 'lodash';
import { SettingsConsumer } from '../../context/settings';
import ChallengesCard from '../challengesCard';
import { LEADERBOARD_API_PATH } from '../../endpoints';
import axiosInstance from 'Src/common/utilities/axios_util';
import HonorWall from '../honorWall';
import InfluencerLeaderboard from '../leaderboards/influencerLeaderboard';
import GroupLeaderboard from '../leaderboards/groupLeaderboard';
import CampaignHubs from '../campaignHubs';
import { fetchHub } from 'Src/alumniGiving/actions/settings';
import {
  DESCRIPTION_SECTION,
  CHALLENGE_SECTION,
  CAMPAIGN_HUB_SECTION,
  TRIBUTE_SECTION,
  LEADERBOARD_SECTION,
} from 'Src/alumniGiving/constants';
import LottieLoader from 'Src/common/components/lottieLoader';

const AfterCampaignStarted = () => {
  const [settings] = SettingsConsumer();
  const [loadedHubs, setLoadedHubs] = useState(false);
  const [loadedLeaderBoard, setLoadedLeaderBoard] = useState(false);
  const [leaderboards, setLeaderboards] = useState([]);
  const [hubs, setHubs] = useState([]);

  const getHubs = async () => {
    setLoadedHubs(false);
    try {
      const response = await fetchHub(settings?.page?.id);
      if (response && response.data) {
        setHubs(response.data);
      }
      setLoadedHubs(true);
    } catch (err) {
      setLoadedHubs(true);

      setHubs([]);
    }
  };

  useEffect(() => {
    getHubs();
    setLoadedLeaderBoard(false);
    axiosInstance({
      method: 'get',
      url: LEADERBOARD_API_PATH.replace('{}', settings.page.id),
    })
      .then((response) => {
        if (response.data.count) {
          setLeaderboards(response.data.results);
        }
        setLoadedLeaderBoard(true);
      })
      .catch(() => setLoadedLeaderBoard(true));
  }, []);

  const renderComponent = ({ sectionType, sectionId, colSize }) => {
    switch (sectionType) {
      case 'description':
        return (
          <>
            <Col span={24}>
              <Card
                bordered={false}
                className="campaign-details-block arc-card-box-shadow arc-card-border-radius fr-view">
                <div
                  className="page-description"
                  dangerouslySetInnerHTML={{
                    __html: settings.page.description,
                  }}
                />
              </Card>
            </Col>
          </>
        );
      case 'tribute':
        return <HonorWall />;
      case 'challenges':
        return <ChallengesCard />;
      case 'leaderboard':
        const reqLeaderboard = find(leaderboards, ({ id }) => id === sectionId);
        if (
          reqLeaderboard &&
          (reqLeaderboard.is_generosity_leaderboard_enabled || reqLeaderboard.is_participation_leaderboard_enabled)
        ) {
          return (
            <Col span={24}>
              <Choose>
                <When condition={reqLeaderboard.type === 'default'}>
                  <InfluencerLeaderboard leaderboard={reqLeaderboard} />
                </When>
                <Otherwise>
                  <GroupLeaderboard leaderboard={reqLeaderboard} />
                </Otherwise>
              </Choose>
            </Col>
          );
        }
        return <></>;
      case 'campaignHub':
        const regHub = find(hubs, ({ id }) => id === sectionId);
        if (regHub)
          return (
            <Col span={24}>
              <CampaignHubs CampaignHubData={regHub} />
            </Col>
          );
        return <></>;
    }
  };

  const buildSectionOrder = () => {
    let result = [];
    let sections = settings?.page?.sections || [];
    sections = sections.sort((a, b) => a.display_order - b.display_order);
    for (let section of sections) {
      switch (section.section.section_type) {
        case DESCRIPTION_SECTION:
          result.push({ sectionType: 'description' });
          break;
        case CHALLENGE_SECTION:
          result.push({ sectionType: 'challenges' });
          break;
        case TRIBUTE_SECTION:
          if (settings.page.is_gift_tribute_enabled) {
            result.push({ sectionType: 'tribute' });
          }
          break;
        case CAMPAIGN_HUB_SECTION:
          result.push({ sectionType: 'campaignHub', sectionId: parseInt(section.section.section_id) });
          break;
        case LEADERBOARD_SECTION:
          result.push({ sectionType: 'leaderboard', sectionId: parseInt(section.section.section_id) });
          break;
        default:
          break;
      }
    }
    return result;
  };

  if (loadedHubs && loadedLeaderBoard) return buildSectionOrder().map((data) => renderComponent(data));
  return (
    <div className="sections-loader">
      <LottieLoader />
    </div>
  );
};

export default AfterCampaignStarted;
