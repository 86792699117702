import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';

const maxLines = 3;
function PageInfo() {
  const [expanded, setExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const lineHeight = parseInt(window.getComputedStyle(contentRef.current).lineHeight, 10);
      const maxHeight = lineHeight * maxLines;
      if (contentRef.current.scrollHeight > maxHeight) {
        setShowButton(true);
      }
    }
  }, []);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="page-info p20">
      <h1 className="arc-H400 arc-color-black arc-font-weight-medium-imp mb12">{window.pageData.name}</h1>

      <div
        ref={contentRef}
        style={{
          maxHeight: expanded ? 'none' : `${maxLines * 1.57}em`,
          overflow: 'hidden',
          position: 'relative',
          transition: 'max-height 0.3s ease',
          whiteSpace: 'pre-wrap',
          display: expanded ? 'block' : '-webkit-box',
          WebkitLineClamp: maxLines,
          WebkitBoxOrient: 'vertical',
        }}
        className="arc-p"
        dangerouslySetInnerHTML={{ __html: window.pageData.description }}
      />
      {showButton && (
        <Button type="link" className="link-btn arc-link-btn mt4" onClick={toggleExpanded}>
          {expanded ? 'View Less' : 'View More'}
          <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} className="ml4 arc-color-primary" />
        </Button>
      )}
    </div>
  );
}

export default PageInfo;
