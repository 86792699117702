/* eslint-disable */

import React, { useEffect, useState, useRef, useContext } from 'react';
import { withRouter } from 'react-router';
import { matchPath } from 'react-router-dom';
import { Card, Col, Row } from 'antd';
import { find, isEmpty } from 'lodash';
import queryString from 'query-string';
import * as Sentry from '@sentry/react';
import GoogleCalenderLink from 'Src/common/components/googleCalenderLink';
import CountDown from 'Src/common/components/countDown';
import { getUTCDate } from 'Src/common/utilities/moment_util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/pro-duotone-svg-icons';
import { SettingsConsumer } from '../context/settings';
import { PaymentLinkConsumer } from '../context/paymentLink';
import CampaignGoalBlock from '../campaign/blocks/goal';
import CampaignCalendarBlock from '../campaign/blocks/calendar';
import CampaignDonateBlock from '../campaign/blocks/donate';
import DonateWithCountDown from '../campaign/blocks/donateWithCountDown';
import PaymentModal from '../paymentModal';
import InfluenceCard from './leaderboards/influencerLeaderboard/influenceCard';
import AfterCampaignStarted from './afterCampaignStarted';
import {
  GIVING_PAY_PATH,
  GIVING_GIFT_SUCCESS_PAYMENT_PATH,
  GIVING_GIFT_FAILED_PAYMENT_PATH,
  GIVING_GIFT_SHARE_PATH,
} from '../routes';
import { fetchHub } from 'Src/alumniGiving/actions/settings';
import { CalendarBlockText } from '../constants';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter/index';
import AlmasightsContext from 'Src/common/context/almasights';
import { TOUCHPOINTS } from 'Src/alumniGiving/constants';
import { useSelector } from 'react-redux';
import { setCampaignIdAction, setCampaignHubFunds, setSelectedCampaignData } from '../redux/reducers/campaignHub';
import CampaignHubs from './campaignHubs';
import { CAMPAIGN_HUB_SECTION } from 'Src/alumniGiving/constants';
import store from '../redux/store';
import Share from '../campaign/blocks/share';

import './style.scss';

const LandingPage = (props) => {
  const [settings] = SettingsConsumer();
  const [paymentLink] = PaymentLinkConsumer();
  const [hubs, setHubs] = useState([]);
  const [loadedHubs, setLoadedHubs] = useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const { isLoading, almasightsInstance } = useContext(AlmasightsContext);
  const [isFixedDonarBtnInvisible, setIsFixedDonarBtnInvisible] = useState(true);
  const CampaignDonateRefElement = useRef(null);
  const selectedCampaignId = useSelector((state) => state.campaignDataReducer.selectedCampaignId);

  const getHubs = async () => {
    setLoadedHubs(false);
    try {
      const response = await fetchHub(settings?.page?.id);
      if (response && response.data) {
        setHubs(response.data);
      }
      setLoadedHubs(true);
    } catch (err) {
      setLoadedHubs(true);
      setHubs([]);
    }
  };

  useEffect(() => {
    getHubs();
  }, []);

  const renderCampaignHub = (sectionId) => {
    const regHub = find(hubs, ({ id }) => id === sectionId);
    if (regHub)
      return (
        <Col span={24}>
          <CampaignHubs CampaignHubData={regHub} />
        </Col>
      );
    return <></>;
  };

  const getCampaignHubs = () => {
    let result = [];
    let sections = settings?.page?.sections || [];
    result = sections
      .filter((section) => section.section.section_type === CAMPAIGN_HUB_SECTION)
      .sort((a, b) => a.display_order - b.display_order)
      .map((section) => parseInt(section.section.section_id));

    return result;
  };

  useEffect(() => {
    const matchedPath = matchPath(window.location.pathname, {
      path: GIVING_PAY_PATH,
    });
    const matchedCompleteGiftPath = matchPath(window.location.pathname, {
      path: GIVING_GIFT_SUCCESS_PAYMENT_PATH,
    });
    const matchFailedPaymentPath = matchPath(window.location.pathname, {
      path: GIVING_GIFT_FAILED_PAYMENT_PATH,
    });
    const matchSharePath = matchPath(window.location.pathname, {
      path: GIVING_GIFT_SHARE_PATH,
    });
    const queryS = queryString.parse(props.location.search);
    let openPaymentModal = null;
    if (!isEmpty(queryS)) {
      openPaymentModal = queryS.is_payment_modal_open;
    }

    if (
      matchedPath ||
      matchedCompleteGiftPath ||
      matchFailedPaymentPath ||
      matchSharePath ||
      !isEmpty(paymentLink.data) ||
      openPaymentModal
    ) {
      setIsPaymentModalVisible(true);
    }
  }, []);

  function isInViewport(Element) {
    if (!Element || isEmpty(Element.current)) return false;
    const { y, height } = Element.current.getBoundingClientRect();
    return y + height > 0;
  }

  function onScroll() {
    setIsFixedDonarBtnInvisible(isInViewport(CampaignDonateRefElement));
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  function openPaymentModal() {
    if (!settings.page.external_url) {
      setIsPaymentModalVisible(true);
    } else {
      window.open(settings.page.external_url, '_blank');
    }
  }

  useEffect(() => {
    if (!isLoading) {
      // Create a "Viewed giving page" almasights touchpoint
      almasightsInstance.trackEvent({
        touchpointType: TOUCHPOINTS.VIEWED_GIVING_PAGE,
        triggerType: 'view',
        authToken: window.csrfToken,
        object: null,
      });
    }
  }, [isLoading]);

  function closeModal(shareId) {
    setIsPaymentModalVisible(false);
    store.dispatch(setCampaignIdAction(null));
    store.dispatch(setSelectedCampaignData(null));
    store.dispatch(setCampaignHubFunds(null));
    props.history.push(`/g/${settings.page.slug}/${shareId ? `?r=${shareId}` : ''}`);
  }

  const showDonateBlock = ({ isCountDownCompleted }, { page }) => {
    if (page.accounts && page.accounts.length) {
      if (!isCountDownCompleted && page.accept_early_gift) {
        return true;
      }
      if (isCountDownCompleted) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <div>
      <div className="campaign-donate-wrapper-v1">
        <Row type="flex" justify="space-between" gutter={32}>
          <Col lg={14} md={14} sm={12} xs={24}>
            <div className="mb16">
              <p className="arc-color-black campaign-donate-title">{window.pageData.name}</p>
            </div>
            <Choose>
              <When condition={settings.page.goal && props.isCountDownCompleted}>
                <CampaignGoalBlock />
              </When>
              <Otherwise>
                <CampaignCalendarBlock
                  name={settings.page.name}
                  startDateTime={settings.page.start_datetime}
                  endDateTime={settings.page.end_datetime}
                  showTime={false}
                  timezone={settings.page.timezone}
                  venue={settings.page.venue}
                />
              </Otherwise>
            </Choose>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24}>
            <Card className="donate-block-card">
              <If condition={props.displayStartIn && props.startDateTime}>
                <h3 className=" start-in arc-H100 arc-text-uppercase">
                  <I18nCustomFormatter id="starts-in-label" defaultMessage="starts in" />
                </h3>
              </If>
              <If condition={props.showCountDown && props.displayStartIn && props.startDateTime}>
                <CountDown dateTime={props.startDateTime} callback={props.countDownCallback} />
              </If>
              <If condition={props.showCountDown && !props.displayStartIn && props.endDateTime}>
                <CountDown dateTime={props.endDateTime} callback={() => {}} />
              </If>
              <If condition={showDonateBlock(props, settings)}>
                <CampaignDonateBlock
                  onRef={CampaignDonateRefElement}
                  endDate={settings.page.end_datetime}
                  disableGoogleApplePayEnabled
                  openPaymentModal={openPaymentModal}
                  callToActionText={
                    props.isCountDownCompleted
                      ? settings.page.call_to_action_text
                      : settings.page.accept_early_gift_text
                  }
                />
              </If>
            </Card>
            <div className="arc-d-flex arc-flex-vertical-align arc-flex-justify-center mt12">
              <Share title={settings.page?.name} url={settings.page?.page_url} />
            </div>
          </Col>
        </Row>
      </div>
      <div className="campaign-details-block-wrapper landing-page">
        <Row className="campaign-details-block-row" type="flex" justify="space-between">
          <If condition={props.isCountDownCompleted}>
            <AfterCampaignStarted />
          </If>
          <If condition={!props.isCountDownCompleted}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Row
                className="getting-ready-container"
                type="flex"
                justify="space-between"
                gutter={[32, 32]}
                align="top">
                <Col lg={12} md={12} sm={24} xs={24} className="mark-your-calendar-row">
                  <div className="secondary-calender-link">
                    <div id="share-calender-wrapper">
                      <div className="share-calender-heading">
                        <FontAwesomeIcon icon={faCalendarPlus} className="share-calender-icon" />
                        <p className="arc-color-black">
                          <I18nCustomFormatter id={CalendarBlockText.title} />
                        </p>
                      </div>
                      <div className="share-calender-content">
                        <p className="arc-p mb16">
                          <I18nCustomFormatter id={CalendarBlockText.description} />
                        </p>
                        <GoogleCalenderLink
                          style={{ background: '#000' }}
                          className="arc-color-primary unique-link mt4"
                          title={<I18nCustomFormatter id="add-to-calendar-label" />}
                          eventTitle={settings.page.name}
                          startDateTime={getUTCDate(settings.page.start_datetime, 'YYYYMMDDTHHmm00')}
                          endDateTime={getUTCDate(settings.page.end_datetime, 'YYYYMMDDTHHmm00')}
                          location={settings.page.venue && settings.page.venue.formatted_address}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24} className="countdown-influencer-row">
                  <InfluenceCard />
                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Card
                    bordered={false}
                    className="campaign-details-block arc-card-box-shadow arc-card-border-radius fr-view">
                    <div
                      className="page-description"
                      dangerouslySetInnerHTML={{
                        __html: settings.page.countdown_description || settings.page.description,
                      }}
                    />
                  </Card>
                </Col>
                <If condition={settings.page.countdown_getting_ready_desc}>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Card
                      bordered={false}
                      className="campaign-details-block arc-card-box-shadow arc-card-border-radius fr-view">
                      <div
                        className="page-description"
                        dangerouslySetInnerHTML={{
                          __html: settings.page.countdown_getting_ready_desc,
                        }}
                      />
                    </Card>
                  </Col>
                </If>
                <If condition={!isEmpty(hubs) && showDonateBlock(props, settings)}>
                  <Row className="campaign-details-block-row p16">
                    {getCampaignHubs().map((campaignId) => renderCampaignHub(campaignId))}
                  </Row>
                </If>
              </Row>
            </Col>
          </If>
        </Row>
      </div>
      <If condition={isPaymentModalVisible || selectedCampaignId}>
        <PaymentModal closeModal={closeModal} {...props} />
      </If>
      <If condition={showDonateBlock(props, settings) && settings.page.accept_gifts}>
        <DonateWithCountDown
          hideBtn={isFixedDonarBtnInvisible}
          callToActionText={
            props.isCountDownCompleted ? settings.page.call_to_action_text : settings.page.accept_early_gift_text
          }
          openPaymentModal={openPaymentModal}
          dateTime={props.isCountDownCompleted ? settings.page.end_datetime : settings.page.start_datetime}
        />
      </If>
    </div>
  );
};

export default Sentry.withProfiler(withRouter(LandingPage));
